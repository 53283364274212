import React from 'react';
import useInterval from './useInterval';

function useSlider({ total = 0, speed = 5000 }) {
  const [currentImage, setCurrentImage] = React.useState(0);

  function nextImage() {
    if (currentImage === total - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  }

  useInterval(() => {
    nextImage();
  }, speed);

  return { currentImage, setCurrentImage };
}

export default useSlider;
