import React from 'react';
import cn from 'classnames';

import Button from 'components/Button';

import serviceImage1 from 'images/services-images/service1.png';
import serviceImage2 from 'images/services-images/service2.png';
import serviceImage3 from 'images/services-images/service3.png';
import logo1 from 'images/services-images/logo1.png';
import logo2 from 'images/services-images/logo2.png';
import logo3 from 'images/services-images/logo3.png';
import serviceIcon1 from 'images/services-images/serviceIcon1.png';
import serviceIcon2 from 'images/services-images/serviceIcon2.png';
import serviceIcon3 from 'images/services-images/serviceIcon3.png';

import styles from 'pages/IndexPage/IndexPage.module.scss';

const services = [
  {
    heading: {
      image: serviceImage1,
      title: (
        <h5 className={cn(styles.serviceTitle, styles.serviceTitle1)}>
          Vorsorgen & Verfügen
        </h5>
      ),
      subtitle: 'Den eigenen Willen und Klarheit hinterlassen'
    },
    content: {
      features: [
        {
          subtitle: 'Persönliche Cloud:',
          text:
            'Cloudbasiertes Hinterlegen, Erstellen und Verwalten von Dokumenten, wie z.B. ' +
            'Patientenverfügung, Vorsorgevollmacht, Testament und im Notfall im Zentralregister finden lassen'
        },
        {
          subtitle: 'Verfügen von Produkten und Dienstleistern:',
          text:
            'Auswahl und Festlegung von z.B. Urne, Krematorium, Floristik, Trauerredner:in, Musiker:in, Trauerfeier-Ablauf, Finanzierungen & Versicherungen.'
        },
        {
          subtitle: 'Unterstützung durch Expert:innen:',
          text:
            'Ausgebildete VorsorgePlaner:innen helfen Ihnen bei der Vorsorge und stehen Ihnen bei allen Fragen beratend zur Seite'
        }
      ],
      button: (
        <Button
          type="primary"
          color="pink"
          title="MEHR ERFAHREN"
          link="//vorsorgeplaner.online?utm_source=about.convela.cloud"
        />
      ),
      logo: <img src={logo1} alt="geprüfte Sicherheit" />,
      description: 'Wir hosten Ihre Daten sicher in Deutschland',
      icon: <img src={serviceIcon1} alt="SSL Secure" style={{ width: '98px' }} />
    }
  },
  {
    heading: {
      image: serviceImage2,
      title: <h5 className={cn(styles.serviceTitle, styles.serviceTitle2)}>Bestatten</h5>,
      subtitle: 'Den passenden Abschied bereiten'
    },
    content: {
      features: [
        {
          subtitle: 'Geprüfte Dienstleister:',
          text: 'Bestatter:innen, Trauerredner:innen, Trauermusiker:innen'
        },
        {
          subtitle: 'Gut designte Produkte',
          text: 'z.B. Särge, Urnen, Kränze'
        },
        {
          subtitle: 'Transparente Prozesse und Services:',
          text: 'Von der Abholung am Sterbeort bis zur Beisetzung'
        }
      ],
      button: (
        <Button
          type="primary"
          color="pink"
          title="MEHR ERFAHREN"
          link="//convela.cloud/categories"
        />
      ),
      logo: <img src={logo2} alt="geprüfte Sicherheit" />,
      description: 'Wir garantieren Ihnen geprüfte Sicherheit',
      icon: (
        <img src={serviceIcon2} alt="geprüfte Sicherheit" style={{ width: '110px' }} />
      )
    }
  },
  {
    heading: {
      image: serviceImage3,
      title: (
        <h5 className={cn(styles.serviceTitle, styles.serviceTitle3)}>
          Trauern & Gedenken
        </h5>
      ),
      subtitle: 'Den Gefühlen Ausdruck verleihen, der Erinnerung einen Ort geben'
    },
    content: {
      features: [
        {
          subtitle: 'Erfahrene Dienstleister:',
          text: 'z.B. Trauerbegleiter:innen, Selbsthilfegruppen'
        },
        {
          subtitle: 'Wegweisende Produkte:',
          text: 'z.B. Erinnerungsbücher, Trauer-Tattoos, Erinnerungsschmuck'
        },
        {
          subtitle: 'Kraftgebende Services:',
          text: 'z.B. Gedenkseiten, Aktion Lichtpunkt'
        }
      ],
      button: (
        <Button
          type="primary"
          color="pink"
          title="MEHR ERFAHREN"
          link="//gedenkseiten.de?utm_source=about.convela.cloud"
        />
      ),
      logo: <img src={logo3} alt="geprüfte Sicherheit" />,
      description: 'Wir bieten überdauernde Leistungen',
      icon: (
        <img src={serviceIcon3} alt="überdauernde Leistungen" style={{ width: '50px' }} />
      )
    }
  }
];

export default services;
