import React, { useState } from 'react';
import cn from 'classnames';

import { isInProduction } from 'utils/isInProduction';

import styles from './NewsletterForm.module.scss';
import IconPlus from '../../images/svg/IconPlus.svg';

const NewsletterForm = () => {
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [isAgreementHighlighted, setIsAgreementHighlighted] = useState(false);
  const [email, setEmail] = useState('');
  const [requestError, setRequestError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const createRequest = (link) => {
    const productionApi = 'https://convela.cloud';
    const devApi = 'http://dev.convela.cloud';

    return (isInProduction ? productionApi : devApi) + '/backend/api/v2' + link;
  };

  const submitForm = async () => {
    if (!isAgreementChecked) {
      setIsAgreementHighlighted(true);
      return;
    }
    setIsFetching(true);
    const response = await fetch(createRequest('/newsletter-list'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json'
      },
      body: JSON.stringify({ email: email })
    });
    const result = await response.json();
    if (response.status === 201 || result.existing_record_id) {
      setIsFinished(true);
    } else {
      setRequestError(result.errors.email[0]);
    }
    setIsFetching(false);
  };

  const handleSubmit = (e) => {
    submitForm();
    e.preventDefault();
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  if (isFinished) {
    return (
      <div className={styles.finished}>
        <p className={styles.finishedTitle}>Vielen Dank!</p>
        <p className={styles.finishedDescription}>
          Wir werden Sie über Neuigkeiten und Updates des Funeral Marketplace auf dem
          Laufenden halten.
        </p>
      </div>
    );
  }

  return (
    <div className={styles.newsletterFormWrapper}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.errorMessageContainer}>
          <div className={styles.errorMessage}>{requestError && requestError}</div>
        </div>
        <input
          className={cn(styles.emailInput, { [styles.inputError]: requestError })}
          onChange={handleChange}
          type="email"
          placeholder="Ihre E-Mail-Adresse"
          required
        />
        <button className={styles.button} type="submit" disabled={isFetching}>
          {isFetching ? 'wird abonniert...' : 'NEWSLETTER ABONNIEREN'}
        </button>
        <div className={styles.checkbox}>
          <input
            className={styles.checkboxInput}
            type="checkbox"
            checked={isAgreementChecked === true}
            onChange={() => setIsAgreementChecked(!isAgreementChecked)}
            id="permission"
          />
          <label
            htmlFor="permission"
            className={cn(styles.checkboxLabel, {
              [styles.checkboxLabelHighlighted]: isAgreementHighlighted
            })}
          >
            <p>Ich habe den Newsletter-Hinweis* gelesen und willige ein</p>
          </label>
        </div>
        <div className={styles.hint}>
          <input type="checkbox" id="hint" />
          <label htmlFor="hint" className={styles.hintLabel}>
            <span>*) Newsletter-Hinweis anzeigen</span>
            <IconPlus className={styles.closeIcon} />
          </label>
          <div className={styles.hintDescriptionContainer}>
            <div className={styles.hintDescription}>
              <p>
                <span>* </span>Um Sie in unseren Newsletter-Verteiler aufzunehmen,
                benötigen wir eine Bestätigung, dass Sie der Inhaber der angegebenen
                E-Mail-Adresse sind und dass Sie mit dem Empfang des Newsletters
                einverstanden sind. Die erhobenen Daten dienen nur der Versendung des
                Newsletters und der Dokumentation Ihrer Zustimmung. Eine andere
                Verarbeitung oder Weitergabe an Dritte erfolgt nicht. Sie können das
                Abonnement des Newsletters und die Einwilligung zur Speicherung Ihrer
                Daten jederzeit widerrufen. Weitere Informationen entnehmen Sie bitte
                unseren Datenschutzbestimmungen.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewsletterForm;
