import getFmpUrl from 'functions/urls/getFmpUrl';

const faqsItems = [
  {
    question: 'Was ist der „Funeral Marketplace“?',
    answer:
      'Der Funeral Marketplace ist der zentrale Marktplatz der Abschiedsbranche für Privatkunden, Bestatter/Dienstleister und' +
      ' Hersteller/Anbieter. Er bietet Ihnen eine Plattform zur Organisation und Zusammenstellung einer Bestattung.'
  },
  {
    question: 'Für wen ist der Funeral Marketplace gedacht?',
    answer:
      'Diese Plattform ist für Privatkunden, Bestattungsunternehmen/Dienstleister und Hersteller/Anbieter gleichermaßen entwickelt' +
      ' worden. Alle professionellen Akteure der Branche kommen hier zusammen und stehen Hinterbliebenen und Vorsorgenden zur Verfügung.'
  },
  {
    question: 'Welche Leistungen bietet mir der Funeral Marketplace als Privatkunde?',
    answer:
      'Sie müssen akut eine Bestattung organisieren? Oder wollen Sie heute schon Vorsorge' +
      ' für sich und Ihre Liebsten treffen? Dann sind Sie auf dem Funeral Marketplace richtig. Vom Sarg über' +
      ' das größte Urnenangebot, das umfassende Angebotsspektrum der Krematorien, Friedhöfe und Floristen' +
      ' bis hin zum Angebot der Trauerredner, Live-Musiker oder Steinmetze und natürlich des ' +
      ' Bestatters, finden Sie alles auf diesem Marktplatz, was Sie für' +
      ' eine Bestattung benötigen.<br />Auf dem Funeral Marketplace kommt das komplette Angebot aller' +
      ' professionellen Hersteller/Anbieter und Bestatter zusammen und wird für Sie sichtbar.' +
      ' Sie haben die volle Auswahl bei maximaler Kostentransparenz.'
  },
  {
    question: 'Wie kann ich den Funeral Marketplace als Geschäftskunde nutzen?',
    answer:
      'Auf dem Funeral Marketplace können Sie als Unternehmen einen Business-Account erstellen und damit für Ihren eigenen Bedarf' +
      ' einkaufen, Ihr Unternehmen präsentieren und Ihren eigenen Shop aufbauen, mit dem Sie an andere Business-Kunden und Privatkunden verkaufen können.'
  },
  {
    question: 'Kann ich als Privatkunde auf dem Funeral Marketplace Produkte kaufen?',
    answer:
      'Natürlich! Sie können Produkte und Dienstleistungen kaufen bzw. buchen, um vorzusorgen, zu bestatten, zu trauern oder zu gedenken.' +
      ' Die meisten Produkte kaufen Sie über den von Ihnen ausgewählten Dienstleister, einen Hinweis dazu finden Sie in jeder Produktkategorie.'
  },
  {
    question: 'Wie sicher ist der Funeral Marketplace?',
    answer:
      'Die von Ihnen hinterlegten Informationen und Dokumente sind jederzeit sicher. Der Schutz Ihrer Daten und höchste technische Sicherheit sind für uns von zentraler Bedeutung. Daher hosten wir Ihre Daten sicher und entsprechend der DSGVO ausschließlich auf Servern in Deutschland und nutzen verschlüsselte SSL-Verbindungen.' +
      ` Mehr zu unserem Datenschutz finden Sie in der <u><a href="${getFmpUrl(
        'privacy'
      )}" target="_blank">Datenschutzerklärung</a></u>.`
  }
];

export default faqsItems;
