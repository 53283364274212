import React, { useEffect } from 'react';
import cn from 'classnames';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

// Layout component should come first in imports
// since it holds global styles and css reset which should be applied first
import Layout from 'components/Layout';
import ContentContainer from 'components/ContentContainer';
import BackgroundSlider from 'components/BackgroundSlider';
import Seo from 'components/seo';
import NewsletterForm from 'components/NewsletterForm/NewsletterForm';
import KnownLogos from 'components/KnownLogos';
import Faqs from 'components/FaqSection';
import Contacts from 'components/ContactsSection';
import InfoSection from 'components/InfoSection';
import Button from 'components/Button';

import FeatureIcon from 'images/svg/FeatureIcon.svg';
import ServicesTitle from 'images/svg/GradientTitle.svg';
import aboutUsImage from 'images/aboutUsImage.png';
import heroLogo from 'images/hero-images/heroLogo.png';

import heroImages from '../../constants/heroImages';
import services from '../../constants/services';
import faqsItems from '../../constants/faqsItemsIndexPage';

import styles from './IndexPage.module.scss';

const IndexPage = () => {
  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);

  return (
    <Layout>
      <Seo
        title="About"
        description="Vorsorgen & Verfügen · Bestatten · Trauern & Gedenken, alle Abschiedsthemen über eine Plattform – Finden Sie Online-Services, Produkte und Dienstleister, die Ihnen helfen, alle Abschiedsthemen in Ihrem Sinne zu gestalten: selbstbestimmt, ästhetisch und in guter Erinnerung."
        imageURL={aboutUsImage}
        faq={faqsItems}
      />
      <ParallaxProvider>
        <ContentContainer>
          <div className={styles.section}>
            <BackgroundSlider images={heroImages}>
              <div className={styles.hero}>
                <img className={styles.heroLogo} src={heroLogo} alt="FMP" />
                <div className={styles.heroTitle}>
                  <div className={styles.heroTitleText}>
                    Den Zeitpunkt für den Abschied{' '}
                    <br className={styles.tabletLaptopBreak} />
                    kann man nicht planen.
                    <br />
                    <br />
                    <span>Die Umstände schon.</span>
                  </div>
                </div>
                <Button
                  className={styles.heroCTA}
                  type="primary"
                  color="white"
                  title="JETZT PLANEN"
                  link="/vorsorgen-und-verfuegen/bestattung-online-planen"
                />
              </div>
            </BackgroundSlider>
          </div>
          <div className={cn(styles.section, styles.servicesSection)} id="services">
            <h3 className={styles.sectionTitle} style={{ marginTop: '20px' }}>
              Online-Services, Produkte, Dienstleister
            </h3>
            <h4 className={styles.sectionSubtitle}>
              Alle Abschiedsthemen über eine Plattform:
            </h4>
            <div className={styles.servicesTitle}>
              <ServicesTitle />
            </div>
            <div className={styles.services}>
              {services.map((service, serviceNumber) => (
                <div className={styles.service} key={serviceNumber}>
                  <Parallax
                    className={styles.serviceImage}
                    y={[0, -36 + serviceNumber * 16]}
                  >
                    <img src={service.heading.image} alt={service.heading.title} />
                  </Parallax>
                  <img
                    className={styles.serviceImageMobile}
                    src={service.heading.image}
                    alt={service.heading.title}
                    data-aos="fade"
                    data-aos-offset="100"
                  />
                  {service.heading.title}
                  <p className={styles.servicesSubtitle}>{service.heading.subtitle}</p>
                  <div className={styles.serviceContent}>
                    <div className={styles.serviceFeatures}>
                      {service.content.features.map((feature, featureNumber) => (
                        <div className={styles.serviceFeature} key={featureNumber}>
                          <FeatureIcon />
                          <div className={styles.featureText}>
                            <p className={styles.featureTitle}>{feature.subtitle}</p>
                            <p className={styles.featureDescription}>{feature.text}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={styles.serviceFooter}>
                      <div className={styles.upToTabletLandscape}>
                        {service.content.button}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.security}>
              <h4 className={styles.securityTitle}>Datensicherheit</h4>
              <div className={styles.securityItems}>
                {services.map((service, serviceNumber) => (
                  <div className={styles.securityItem} key={serviceNumber}>
                    <div className={styles.securityLogo}>{service.content.logo}</div>
                    <p className={styles.securityDescription}>
                      {service.content.description}
                    </p>
                    <div className={styles.securityIconContainer}>
                      {service.content.icon}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.section} style={{ border: 'none' }} id="about-us">
            <div className={styles.aboutUsVideoContainer}>
              <Parallax
                y={[-20, 20]}
                className={styles.aboutUsVideo}
                styleInner={{
                  position: 'absolute',
                  top: -80,
                  right: -100,
                  bottom: -40,
                  left: -100
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/468502561?background=1?&byline=0&portrait=0"
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </Parallax>
              <p className={styles.aboutUsVideoText}>
                Für emotionale Momente <br /> im Hier und{' '}
                <br className={styles.mobileBreak} /> Jetzt.
              </p>
            </div>
            <div className={styles.aboutUsContainer}>
              <div className={styles.aboutUsTitleMobile}>
                <h3 className={styles.sectionTitle}>Über uns: Funeral Marketplace</h3>
                <h4 className={styles.sectionSubtitle}>
                  Wir digitalisieren und demokratisieren die Abschiedsbranche.
                </h4>
              </div>
              <div
                className={styles.upToTabletLandscape}
                data-aos="fade"
                data-aos-offset="100"
              >
                <Parallax className={styles.aboutUsImage} y={[-6, 6]}>
                  <img src={aboutUsImage} alt="Funeral Marketplace" />
                </Parallax>
              </div>
              <div className={styles.aboutUsTextContainer}>
                <div className={styles.aboutUsTitle}>
                  <h3 className={styles.sectionTitle}>Über uns: Funeral Marketplace</h3>
                  <h4 className={styles.sectionSubtitle}>
                    Wir digitalisieren und demokratisieren die Abschiedsbranche.
                  </h4>
                </div>
                <div className={styles.aboutUsText}>
                  Abschiedsthemen sind nicht leicht. Aus diesem Grund haben wir es uns zur
                  Aufgabe gemacht, Ihnen alle damit verbundenen Aufgaben, Entscheidungen
                  und Handlungen so einfach wie möglich zu machen. Dazu entwickeln und
                  digitalisieren wir Services, machen Produkte digital verfügbar und
                  bestehende Strukturen transparent, testen Hersteller im Hinblick auf
                  Qualität und Einstellung und vernetzen wichtige Akteure. So
                  demokratisieren wir die „Blackbox“ Bestattungsbranche.
                </div>
                <div className={styles.aboutUsFeature}>
                  <div className={styles.aboutUsFeatureTitle}>
                    <span>Für Sie bedeutet das:</span>
                    <FeatureIcon />
                  </div>
                  <p className={styles.aboutUsFeatureDescription}>
                    Mehr Selbstbestimmung, mehr Auswahl, mehr Einflussnahme, mehr
                    Transparenz und mehr Qualität in allen Themen – im Vorsorgen und
                    Verfügen, im Bestatten sowie im Trauern und Gedenken.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.aboutUsFeatureTablet}>
              <div className={styles.aboutUsFeatureTitle}>
                <span>Für Sie bedeutet das:</span>
                <FeatureIcon />
              </div>
              <p className={styles.aboutUsFeatureDescription}>
                Mehr Selbstbestimmung, mehr Auswahl, mehr Einflussnahme, mehr Transparenz
                und mehr Qualität in allen Themen – im Vorsorgen und Verfügen, im
                Bestatten sowie im Trauern und Gedenken.
              </p>
            </div>
          </div>
          <div
            className={cn(styles.section, styles.blueSection)}
            style={{ marginTop: 30 }}
          >
            <h3 className={styles.sectionTitle}>Bekannt aus</h3>
            <KnownLogos />
          </div>
          <div className={cn(styles.section, styles.faqSection)} id="faq">
            <h3 className={styles.sectionTitle}>FAQ</h3>
            <h4 className={styles.sectionSubtitle}>
              Klare Antworten auf Fragen,
              <br />
              die uns immer wieder gestellt werden.
            </h4>
            <Faqs faqsItems={faqsItems} color="red" />
          </div>
          <div className={cn(styles.section, styles.newsletterSection)} id="newsletter">
            <h3 className={styles.sectionTitle}>Newsletter</h3>
            <h4 className={styles.sectionSubtitle}>Wissen, was möglich ist.</h4>
            <div className={styles.newsletterDescription}>
              <p>
                Neue Features und Funktionen, neue Hersteller und Produkte, neue
                Brancheninfos:
              </p>
              Melden Sie sich hier mit Ihrer E-Mail-Adresse kostenlos zu unserem
              Newsletter an, den wir vierteljährlich <br className={styles.laptopBreak} />{' '}
              versenden, und halten Sie sich auf sich auf dem Laufenden über das, was die
              Abschiedsbranche bewegt.
            </div>
            <NewsletterForm />
          </div>
          <div className={styles.contactSection} id="contact">
            <h3 className={styles.sectionTitle}>Kontakt</h3>
            <h4 className={styles.sectionSubtitle}>
              Fragen, Anregungen, Kritik?
              <br />
              Gern.
            </h4>
            <Contacts />
          </div>
          <div id="info">
            <InfoSection backToToplink="/#page-start" />
          </div>
        </ContentContainer>
      </ParallaxProvider>
    </Layout>
  );
};

export default IndexPage;
