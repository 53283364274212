import React from 'react';
import PropTypes from 'prop-types';
import useSlider from './useSlider';
import { Parallax } from 'react-scroll-parallax';
import cn from 'classnames';

import styles from './BackgroundSlider.module.scss';

const BackgroundSlider = ({ images, children }) => {
  const { currentImage } = useSlider({
    total: images.length,
    speed: 4500
  });

  return (
    <div className={styles.sliderWrapper}>
      {/*Preload images*/}
      {images.map((image, index) => (
        <div
          style={{
            background: `url(${image}) no-repeat -9999px -9999px`
          }}
          key={index}
        />
      ))}
      <Parallax
        className={styles.backgroundContainer}
        styleInner={{
          position: 'absolute',
          top: -80,
          right: 0,
          bottom: -40,
          left: 0
        }}
        y={[-30, 30]}
        tagOuter="figure"
      >
        <div
          className={styles.backgroundImage}
          style={{
            backgroundImage: `url(${images[currentImage]})`
          }}
        />
      </Parallax>
      {children}
      <div className={styles.slideCounters}>
        {images.map((image, index) => (
          <div
            className={cn(styles.slideCounter, {
              [styles.slideCounter_active]: currentImage === index
            })}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

BackgroundSlider.propTypes = {
  children: PropTypes.object,
  images: PropTypes.array
};

export default BackgroundSlider;
